<template>
  <footer class="footer">
    <span class="copyright">&copy; 2021-2025 Clever Home Labs GmbH</span>
    <ul class="footer-links list-inline float-right">
      <li class="list-inline-item"><a href="mailto:thomas@bauche-hamburg.de">Software</a></li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  font-size: 13px;
  color: #666;
  line-height: 40px;
  border-top: 1px solid #ddd;
  margin-top: 50px;

  .list-inline-item {
    margin-right: 10px;
  }

  a {
    color: #666;
  }
}
</style>
